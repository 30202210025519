// Load Bootstrap JS
import bootstrap from 'bootstrap'
import { Offcanvas } from 'bootstrap'

// Load Styles
import '../scss/main.scss';

const hamburger = document.querySelector('.hamburger');
var OffcanvasMenu = document.getElementById('offcanvasMenu');
var _y = window.pageYOffset;

var offcanvasClose = document.getElementsByClassName('offcanvas-close');
var bsOffcanvas = new Offcanvas(OffcanvasMenu);

hamburger.addEventListener('click', function(){ 
    hamburger.classList.add('is-active');
});
OffcanvasMenu.addEventListener('shown.bs.offcanvas', function () {
    hamburger.classList.add('is-active');
});
OffcanvasMenu.addEventListener('hide.bs.offcanvas', function () {
    hamburger.classList.remove('is-active');
});

[ ... offcanvasClose].forEach(function(obj){
    obj.addEventListener('click', function(obj_clicked){
        obj_clicked.preventDefault();
        var ancor = obj_clicked.target.getAttribute('ancor');
        window.scrollTo(0, parseInt(document.getElementById(ancor).offsetTop));
        bsOffcanvas.hide();
    });
});

/*
offcanvasClose.forEach(function(e){
    console.log(e);
});
*/
/*
navMain.addEventListener('show.bs.collapse', function () {
    navBar.classList.remove('bg-transparent');
    navBar.classList.remove('navbar-dark');
    navBar.classList.add('bg-light');
    navBar.classList.add('navbar-light');
    hamburgerInner.classList.remove('light');
    hamburgerInner.classList.add('dark');
});
navMain.addEventListener('hide.bs.collapse', function () {
    if(_y <= 100){
        navBar.classList.remove('bg-light');
        navBar.classList.remove('navbar-light');
        navBar.classList.add('bg-transparent');
        navBar.classList.add('navbar-dark');
        hamburgerInner.classList.remove('dark');
        hamburgerInner.classList.add('light');    
    }
});
window.addEventListener('scroll', function() {
    _y = window.pageYOffset;
    if(navBar){
        if(_y > 100) { // down
            navBar.classList.remove('bg-transparent');
            navBar.classList.remove('navbar-dark');
            navBar.classList.add('bg-light');
            navBar.classList.add('navbar-light');
            hamburgerInner.classList.remove('light');
            hamburgerInner.classList.add('dark');
        } else {
            if(!hamburger_open){
                navBar.classList.remove('bg-light');
                navBar.classList.remove('navbar-light');
                navBar.classList.add('bg-transparent');
                navBar.classList.add('navbar-dark');
                hamburgerInner.classList.remove('dark');
                hamburgerInner.classList.add('light');
            }
        }
    }
}, {passive: true});    
*/